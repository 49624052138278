interface ConstantProps {
  [key: string]: JSX.Element;
}

export const PRIVACY_HISTORY: ConstantProps = {
  '2025.02.27': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>
        - 비회원: 이메일 주소, 휴대전화번호 (문의 유형에 따라 추가 수집되는
        정보: 생년월일, 성별)
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답 등 포함), 성별, 나이, 직업,
      거주지역
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 환불 등에 필요한
      정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용 목적 이외에
      다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지
      않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공, 서비스의 유효성 확인, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 회원이 직접 탈퇴를 신청하거나, 3년간 서비스를
        이용하지 않아 회원자격이 소멸된 경우, 회사는 보유한 개인정보를 지체 없이
        삭제합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
        보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>
        - 아마존웹서비시즈코리아유한책임회사 : 서비스 제공을 위한 클라우드
        서비스 이용 (서울리전)
      </p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
            <th>관련 근거</th>
          </tr>
          <tr>
            <td>
              Intercom, Inc. (수탁자),
              [https://preferences.intercom.com/privacy]
            </td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
            <td rowSpan={3}>
              개인정보 보호법 제28조의8 제1항 제1호 (정보주체의 동의)
            </td>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외
        이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우
        회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이
        경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
        이용이 제한될 수 있습니다.
      </p>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 타겟 및 개인 맞춤
        서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.io</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.io</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2025년 02월 20일</p>
      <p>- 시행일자: 2025년 02월 27일</p>
    </div>
  ),
  '2024.10.31~2025.02.26': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>
        - 비회원: 이메일 주소, 휴대전화번호 (문의 유형에 따라 추가 수집되는
        정보: 생년월일, 성별)
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답 등 포함), 성별, 나이, 직업,
      거주지역
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 환불 등에 필요한
      정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용 목적 이외에
      다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지
      않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공, 서비스의 유효성 확인, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 3년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>
        - 아마존웹서비시즈코리아유한책임회사 : 서비스 제공을 위한 클라우드
        서비스 이용 (서울리전)
      </p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외
        이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우
        회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이
        경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
        이용이 제한될 수 있습니다.
      </p>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 타겟 및 개인 맞춤
        서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.io</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.io</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2024년 10월 02일</p>
      <p>- 시행일자: 2024년 10월 31일</p>
    </div>
  ),
  '2024.05.30~2024.10.30': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>
        - 비회원: 이메일 주소, 휴대전화번호 (문의 유형에 따라 추가 수집되는
        정보: 생년월일, 성별)
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답 등 포함), 성별, 나이, 직업,
      거주지역
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 환불 등에 필요한
      정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용 목적 이외에
      다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지
      않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공, 서비스의 유효성 확인, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>
        - 아마존웹서비시즈코리아유한책임회사 : 서비스 제공을 위한 클라우드
        서비스 이용 (서울리전)
      </p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외
        이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우
        회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이
        경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
        이용이 제한될 수 있습니다.
      </p>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 타겟 및 개인 맞춤
        서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.io</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.io</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2024년 05월 23일</p>
      <p>- 시행일자: 2024년 05월 30일</p>
    </div>
  ),
  '2024.03.06~2024.05.29': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>
        - 비회원: 이메일 주소, 휴대전화번호 (문의 유형에 따라 추가 수집되는
        정보: 생년월일, 성별)
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 환불 등에 필요한
      정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용 목적 이외에
      다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지
      않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공, 서비스의 유효성 확인, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>
        - 아마존웹서비시즈코리아유한책임회사 : 서비스 제공을 위한 클라우드
        서비스 이용 (서울리전)
      </p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외
        이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우
        회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이
        경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
        이용이 제한될 수 있습니다.
      </p>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 타겟 및 개인 맞춤
        서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.io</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.io</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2024년 02월 28일</p>
      <p>- 시행일자: 2024년 03월 06일</p>
    </div>
  ),
  '2023.12.18~2024.03.05': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 환불 등에 필요한
      정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용 목적 이외에
      다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를 제공하지
      않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공, 서비스의 유효성 확인, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>
        - 아마존웹서비시즈코리아유한책임회사 : 서비스 제공을 위한 클라우드
        서비스 이용 (서울리전)
      </p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p>
        이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외
        이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우
        회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이
        경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의
        이용이 제한될 수 있습니다.
      </p>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 타겟 및 개인 맞춤
        서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 12월 11일</p>
      <p>- 시행일자: 2023년 12월 18일</p>
    </div>
  ),
  '2023.11.20~2023.12.17': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
          <tr>
            <td>GMO-Research Inc.</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>성별, 생년월일, 거주 지역</td>
            <td>
              회원 가입 후 개인정보 제3자 제공에 대한 동의에 대한 ‘동의‘ 버튼을
              누른 시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간
              동안 보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 11월 13일</p>
      <p>- 시행일자: 2023년 11월 20일</p>
    </div>
  ),
  '2023.09.28~2023.11.19': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 목적</th>
            <th>제공 항목</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 클라우드 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡
        비즈 메시지 발송 대행
      </p>
      <p>
        - (주)피앤에스정성리서치 : 대면 조사 운영 및 녹화영상 내 개인정보 처리
      </p>
      <p>- 피플인사이트랩 : 대면 조사 운영 및 녹화영상 내 개인정보 처리</p>
      <br />
      <p>수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 09월 21일</p>
      <p>- 시행일자: 2023년 09월 28일</p>
    </div>
  ),
  '2023.06.26~2023.09.27': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡 비즈
        메시지 발송 대행
      </p>
      <p>- (주)피앤에스정성리서치 : 대면 설문 진행 시 영상정보처리</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : privacy@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: privacy@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 06월 19일</p>
      <p>- 시행일자: 2023년 06월 26일</p>
    </div>
  ),
  '2023.04.12~2023.06.25': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 및 “개인정보
        유효기간제”에 따라 1년간 서비스를 이용하지 않은 회원의 경우 회사가
        보유한 개인 정보 및 제3자에 제공된 개인정보를 즉시 삭제하는 것을
        원칙으로 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
        동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(재가입 방지 목적) : 6개월</p>
      <p>- 부정이용 회원회원의 휴대전화번호(부정이용조치 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 처리위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡 비즈
        메시지 발송 대행
      </p>
      <p>- (주)피앤에스정성리서치 : 대면 설문 진행 시 영상정보처리</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: admin@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (police.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 04월 06일</p>
      <p>- 시행일자: 2023년 04월 12일</p>
    </div>
  ),
  '2023.02.01~2023.04.11': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 회사가 보유한 개인 정보
        및 제3자에 제공된 개인정보를 삭제하는 것을 원칙으로 합니다. 단, 다음의
        정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(중복 가입 방지 목적): 6개월</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 취급위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡 비즈
        메시지 발송 대행
      </p>
      <p>- (주)피앤에스정성리서치 : 대면 설문 진행 시 영상정보처리</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: admin@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 01월 26일</p>
      <p>- 시행일자: 2023년 02월 01일</p>
    </div>
  ),
  '2023.01.16~2023.01.31': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 회사가 보유한 개인 정보
        및 제3자에 제공된 개인정보를 삭제하는 것을 원칙으로 합니다. 단, 다음의
        정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호(중복 가입 방지 목적): 6개월</p>
      <p>
        - 계좌주, 은행명, 계좌번호 (포인트 현금출금 과정에서 발생하는 고객
        문의에 대한 과거 신청 내역 확인 목적): 지급일이 포함된 달부터 6개월
      </p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 취급위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡 비즈
        메시지 발송 대행
      </p>
      <p>- (주)피앤에스정성리서치 : 대면 설문 진행 시 영상정보처리</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: admin@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2023년 01월 09일</p>
      <p>- 시행일자: 2023년 01월 16일</p>
    </div>
  ),
  '2022.12.29~2023.01.15': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입을 위해 다음의 개인정보를 처리합니다.
      <p>- (필수) 휴대전화번호, 생년월일, 성별, 거주지역, 직업</p>
      <br />
      둘째, 문의 접수 및 처리를 위하여 다음의 개인정보를 수집 및 처리합니다.
      <p>- 회원: 휴대전화번호, 휴대폰기기정보, 거주지역, 생년월일</p>
      <p>- 비회원: 이메일 주소, 휴대전화번호</p>
      <p>
        고객센터로 문의 시 위와 같은 정보를 수집할 수 있으며, 문의 유형에 따라
        회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
      </p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업, 소득 수준</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 광고식별자(IDFA 또는 ADID를
        개인을 식별할 수 있는 경우에 한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 입금은행, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 회사가 보유한 개인 정보
        및 제3자에 제공된 개인정보를 삭제하는 것을 원칙으로 합니다. 단, 다음의
        정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호 (회원 탈퇴 후 30일간 재가입 방지 목적): 30일</p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      <p>- 방문에 관한 기록 : 3개월(통신비밀보호법)</p>
      <p>
        - 계약 또는 청약 철회에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 대금 결제 및 재화 공급에 관한 기록 : 5년(전자상거래법 등에서 소비자
        보호에 관한 법률)
      </p>
      <p>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관(전자상거래 등에서
        소비자 보호에 관한 법률)
      </p>
      <p className="second_level">
        다. 회원의 명시적인 동의 하에, 설문(객관식 및 주관식)에 응답한
        개인정보(휴대전화번호, 주소, 이름 등)는 동의받은 기간 달성 시
        파기됩니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 취급위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백에스제이 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>- 주식회사 케이티알파 : 기프티콘 발송 대행</p>
      <p>- 11번가 주식회사 : 기프티콘 발송 대행</p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>
        - 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송, 카카오톡 비즈
        메시지 발송 대행
      </p>
      <p>- (주)피앤에스정성리서치 : 대면 설문 진행 시 영상정보처리</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보보호책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CPO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의
        개인정보보호책임자에 할 수 있습니다. 개인정보보호책임자는 이용자들의
        개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
      </p>
      <br />
      <p>[개인정보 열람청구 접수 처리]</p>
      <p>- 담당자: 이건노</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: admin@opensurvey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
      <p>- 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
      <p>- 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
      <p>- 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2022년 12월 21일</p>
      <p>- 시행일자: 2022년 12월 29일</p>
    </div>
  ),
  '2022.10.31~2022.12.28': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입, 상담, 서비스 신청, 프로필 업데이트 등 설문 서비스
      제공을 다음의 개인정보를 처리합니다.
      <p>- 휴대전화번호, 생년월일, 성별, 주소, 학력, 직업, 소득 수준</p>
      <br />
      둘째, 앱 내 문의 접수 및 처리를 위하여 다음의 개인정보를 처리합니다.
      <p>- 휴대전화번호, 성별, 휴대폰기기정보, 응답한 설문 번호, 설문 내용</p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않으며, 사전 동의를 거친 패널의
      결제 내역 정보를 의미
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 기기 고유번호(디바이스 아이디
        또는 IMEI), 광고식별자(IDFA 또는 ADID를 개인을 식별할 수 있는 경우에
        한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 포인트 현금 인출, 기프티콘 선물 발송 과정에서
      다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름, 선물 받는 자의 휴대전화번호</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 회사가 보유한 개인 정보
        및 제3자에 제공된 개인정보를 삭제하는 것을 원칙으로 합니다. 단, 다음의
        정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호 (회원 탈퇴 후 30일간 재가입 방지 목적): 30일</p>
      <p>
        - 회원의 부정 이용에 대한 조치 이력(회원의 악의적 이용의 재발 방지를
        위한 목적): 6개월
      </p>
      <p>
        - 계좌주, 은행명, 계좌번호 (포인트 현금출금 과정에서 발생하는 고객
        문의에 대한 과거 신청 내역 확인 목적): 지급일이 포함된 달부터 6개월
      </p>
      <p>
        - 추천 적립금 지급 이력(회원의 악의적 이용의 방지를 위한 목적): 6개월
      </p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동안 보존한 후
        파기합니다.
      </p>
      - 방문에 관한 기록 : 3개월(통신비밀보호법)
      <p className="second_level">
        다. 회원의 명시적인 동의하에, 설문응답 관련 정보(객관식 및 주관식
        응답)와 해당 설문에 응답한 회원의 성별, 나이, 직업 및 거주지역은 회원
        탈퇴 후 5년간 보유하고, 그 이후에는 개인정보 보호법 및 관련
        가이드라인에서 정하는 바에 따른 가명처리 또는 익명처리를 거쳐 해당
        정보를 보유합니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p>회사의 제3자에 대한 개인정보 제공 내용은 아래와 같습니다.</p>
      <table>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공 항목</th>
            <th>제공 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>(주) 데이터스프링</td>
            <td>제휴 설문 제공, 설문 결과 분석 및 활용</td>
            <td>
              성별, 생년월일, 거주 지역, 직업, 혼인 여부, 설문 응답 관련
              정보(객관식, 주관식 응답 포함)
            </td>
            <td>
              회원 가입 후 개인정보 제공 약관에 대한 ‘동의‘ 버튼을 누른
              시점부터, 회원의 요청으로 개인정보를 삭제하는 시점까지의 기간 동안
              보유 및 이용 가능
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        회사는 회원의 동의를 받거나 법률의 규정 등 ‘개인정보 보호법’에서 허용한
        경우 이외에는 개인정보를 제 3자에게 제공하지 않습니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 취급위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백냉장 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>
        - 주식회사 케이티알파 : 기프티쇼 서비스 운영 및 대행, 시스템 구축 및
        유지 보수, 기프티쇼 서비스 이용 관련상담 업무
      </p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>- 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
            <td>CS 응대 종료 시</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
            <td>
              정보 제공에 동의한 조사 종료 후 30일 *조사 참여자 관리 목적으로
              30일간 보관
            </td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보관리책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CTO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련
        민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
        이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      </p>
      <br />
      <p>[개인정보보호 관련 담당부서]</p>
      <p>- 부서명: 오베이 고객센터</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: thanks@ovey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보침해신고센터 (www.1336.or.kr/국번없이 118)</p>
      <p>- 대검찰청 인터넷범죄수사센터 (icic.sppo.go.kr/02-3480-3600) </p>
      <p>- 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 공고일자: 2022년 10월 24일</p>
      <p>- 시행일자: 2022년 10월 31일</p>
    </div>
  ),
  '2022.06.29~2022.10.30': (
    <div className="terms-body">
      &apos;주식회사 오픈서베이&apos; 는 (이하 &apos;회사&apos;는) 회원님의
      개인정보를 안전하게 보호하기 위하여 최선의 노력을 다하고 있으며, “개인정보
      보호법” 등 개인정보와 관련된 법령 상의 규정들과 방송통신위원회 및
      과학기술정보통신부, 행정안전부 등의 유관기관이 제정한 가이드라인을
      준수하고 있습니다. 회사는 개인정보처리방침을 통하여 회원님께서 제공하시는
      개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
      어떠한 조치가 취해지고 있는지 알려드립니다. 개인정보처리방침은 정부의
      법령이나 지침의 변경이나, 보다 나은 서비스의 제공을 위하여 그 내용이
      변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나
      이메일을 통해서 공지하고 있습니다. 회원님께서는 개인정보처리방침이
      홈페이지 첫 화면의 맨 아래에 표시되어 있으니, 수시로 확인해 주실 것을 부탁
      드립니다.
      <p className="first_level">1. 수집하는 개인정보의 항목 및 수집방법</p>
      <p className="second_level">가. 수집하는 개인정보의 항목</p>
      첫째, 회사는 회원가입, 상담, 서비스 신청, 프로필 업데이트 등 설문 서비스
      제공을 다음의 개인정보를 처리합니다.
      <p>- 휴대전화번호, 생년월일, 성별, 주소, 학력, 직업, 소득 수준</p>
      <br />
      둘째, 앱 내 문의 접수 및 처리를 위하여 다음의 개인정보를 처리합니다.
      <p>- 휴대전화번호, 성별, 휴대폰기기정보, 응답한 설문 번호, 설문 내용</p>
      <br />
      셋째, 설문 진행 및 설문 결과 분석, 통계 분석 서비스 개선, 신규 조사 서비스
      및 데이터 분석 서비스 개발을 위하여 다음과 같이 정보가 처리됩니다.
      <p>- 휴대전화번호, 나이, 성별, 거주지역, 직업</p>
      - 설문응답 관련 정보(객관식, 주관식 응답, 결제정보* 등 포함), 성별, 나이,
      직업, 거주지역
      <br />
      - 결제정보는 결제수단 관련 정보를 포함하지 않음
      <br />
      <br />
      넷째, 서비스 이용과정이나 사업처리 과정에서 아래의 정보가 자동으로
      생성되어 수집될 수 있습니다.
      <p>
        - 서비스 이용 기록, 접속 로그, 결제 기록, 기기 고유번호(디바이스 아이디
        또는 IMEI), 광고식별자(IDFA 또는 ADID를 개인을 식별할 수 있는 경우에
        한함)
      </p>
      <br />
      다섯째, 포인트 지급 및 결제 과정에서 다음의 정보가 수집될 수 있습니다.
      <p>- 계좌번호, 계좌주 이름</p>
      <br />
      <p>
        단, 이 밖에 설문조사의 집단적인 통계분석을 위해서나 이벤트 경품발송을
        위한 목적으로 이용자의 개인정보를 추가 수집하는 경우 반드시 사전에
        이용자에게 해당 사실을 알리고 동의를 거치겠습니다. 더불어 이때 기입하신
        정보는 해당 서비스의 제공이나 회원님께 사전에 밝히고 목적 이외의 다른
        어떠한 목적으로도 사용되지 않습니다.
      </p>
      <p className="second_level">나. 개인정보 수집방법</p>
      회사는 다음과 같은 방법으로 개인정보를 수집합니다.
      <p>- 홈페이지</p>
      <p>- 스마트폰 어플리케이션</p>
      <p>- 제휴사로부터의 제공</p>
      <p>- 생성정보 수집 툴을 통한 수집</p>
      <p className="first_level">2. 개인정보의 수집 및 이용목적</p>
      회사가 개인정보를 수집하는 목적은 이용자의 신분과 서비스 이용 의사를
      최적화되고 맞춤화된 서비스를 제공하기 위함입니다. 회사는 최초 회원가입 시
      서비스 제공을 원활하게 하기 위해 필요한 최소한의 정보만을 수집하고 있으며
      회사가 제공하는 서비스 이용에 따른 요금 정산, 회원 관리, 마케팅 및 환불
      등에 필요한 정보를 추가로 수집할 수 있습니다. 회사는 개인정보를 수집, 이용
      목적 이외에 다른 용도로 이를 이용하거나 이용자의 동의 없이 제3자에게 이를
      제공하지 않습니다.
      <br />
      <br />
      <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
      <p className="second_level">가. 회원 가입 및 회원 관리</p>
      회원제 서비스 이용에 따른 본인 확인, 개인 식별 및 차등화된 서비스 제공,
      불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인
      및 맞춤 서비스 제공
      <p className="second_level">
        나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
      </p>
      통계학적 특성에 따른 설문 대상자 선정 및 설문조사 정보제공을 위한 회원
      프로필 업데이트, 설문 진행 및 설문 결과 분석, 맞춤 서비스 제공, 본인인증,
      포인트 지급 및 결제, 현금출금
      <p className="second_level">다. 문의 접수 및 처리</p> 앱 내 1:1 문의 접수
      및 처리
      <p className="second_level">라. 서비스 개선, 광고 및 마케팅에 활용</p>
      신규 조사 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스
      제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및
      참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
      <p className="first_level">3. 개인정보의 보유 및 이용 기간</p>
      <p className="second_level">
        가. 원칙적으로 개인정보는 회사가 회원에게 서비스를 제공하는 기간 동안에
        한하여 보유 및 이용됩니다. 따라서 회원 탈퇴 시 회사가 보유한 개인 정보가
        삭제되는 것을 원칙으로 합니다. 단 다음의 정보에 대해서는 아래의 이유로
        명시한 기간 동안 보존합니다.
      </p>
      <p>- 회원의 휴대전화번호 (회원 탈퇴 후 30일간 재가입 방지 목적): 30일</p>
      <p>
        - 회원의 부정 이용에 대한 조치 이력(회원의 악의적 이용의 재발 방지를
        위한 목적): 6개월
      </p>
      <p>
        - 계좌주, 은행명, 계좌번호 (포인트 현금출금 과정에서 발생하는 고객
        문의에 대한 과거 신청 내역 확인 목적): 지급일이 포함된 달부터 6개월
      </p>
      <p>
        - 추천 적립금 지급 이력(회원의 악의적 이용의 방지를 위한 목적): 6개월
      </p>
      <p className="second_level">
        나. 관계 법령의 규정에 의해 보존할 필요성이 있는 경우 회사는
        관계법령에서 정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다. 이
        경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, &quot;보존
        근거&quot;에서 명시한 근거에 따라 &quot;보존 기간&quot; 동한 보존한 후
        파기합니다.
      </p>
      - 방문에 관한 기록 : 3개월(통신비밀보호법)
      <p className="second_level">
        다. 회원의 명시적인 동의하에, 설문응답 관련 정보(객관식 및 주관식
        응답)와 해당 설문에 응답한 회원의 성별, 나이, 직업 및 거주지역은 회원
        탈퇴 후 5년간 보유하고, 그 이후에는 개인정보 보호법 및 관련
        가이드라인에서 정하는 바에 따른 가명처리 또는 익명처리를 거쳐 해당
        정보를 보유합니다.
      </p>
      <p className="first_level">4. 개인정보의 파기절차 및 방법</p>
      회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
      지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      <p className="second_level">가. 파기절차</p>
      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
      옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어
      집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는
      이외의 다른 목적으로 이용되지 않습니다.
      <p className="second_level">나. 파기방법</p>- 전자적 파일형태로 저장된
      개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하고, 출력물
      등은 소각하거나 분쇄하는 방식 등에 의하여 파기합니다.
      <p className="first_level">5. 개인정보 제공</p>
      회사는 이용자의 개인정보를 개인정보처리방침의 “개인정보의 수집 및 이용
      목적”에서 고지한 범위 또는 서비스 이용약관에 명시한 범위 내에서만
      사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단,
      다음의 경우 주의를 기울여 개인정보를 이용하거나 제 3자에게 제공할 수
      있습니다.
      <p className="second_level">가. 이용자들이 사전에 동의한 경우</p>
      정보 수집 및 제공을 위하여 이용자가 자발적으로 자신의 개인정보를 제
      3자에게 제공하는 것에 동의한 경우를 뜻하며, 이러한 경우에도 회사는
      이용자에게 (1) 개인정보를 제공받는 자, (2) 그의 이용목적, (3) 제공되는
      개인정보의 항목, (4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에
      대해 명시적·개별적으로 동의를 얻습니다. 이와 같은 모든 과정에 있어서
      회사는 이용자의 의사에 반하여 추가적인 정보를 수집하거나, 동의의 범위를
      벗어난 정보를 제3자와 공유하지 않습니다.
      <p>
        향후 관련 업무의 발생 시, 회원님에게 별도로 알리고 동의 받는 절차가
        진행됩니다.
      </p>
      <p className="second_level">
        나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
      </p>
      <p className="first_level">6. 개인정보의 취급위탁</p>
      회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계
      법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
      규정하고 있습니다.
      <br />
      회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
      <br />
      <br />
      <p>[수탁업체 위탁업무 내용]</p>
      <p>- (주)TS로지스틱스 : 테스트 제품 발송</p>
      <p>- (주)동백냉장 : 테스트 제품 발송</p>
      <p>- (주)케이지이니시스 : 신용카드 결제, 계좌 이체 결제</p>
      <p>- (주)LG U+ : 문자 메세지 발송</p>
      <p>
        - 주식회사 케이티알파 : 기프티쇼 서비스 운영 및 대행, 시스템 구축 및
        유지 보수, 기프티쇼 서비스 이용 관련상담 업무
      </p>
      <p>- 주식회사 다영기획 : 테스트 제품 발송</p>
      <p>- 엔에이치엔 주식회사 : 문자 메시지 발송, 이메일 발송</p>
      <br />
      <p>수집한 수집한 개인정보의 위탁 및 국외이전</p>
      <table>
        <tbody>
          <tr>
            <th>이전받는 업체명 및 연락처</th>
            <th>이전되는 국가</th>
            <th>이전 일시 및 방법</th>
            <th>이전되는 개인정보 항목</th>
            <th>이전받는 자의 이용 목적</th>
          </tr>
          <tr>
            <td>Zendesk, Inc. (수탁자), [privacy@zendesk.com]</td>
            <td>미국</td>
            <td>서비스 이용 시점에 네트워크를 통한 전송</td>
            <td>유저식별을 위한 난수코드, 휴대전화번호</td>
            <td>개인정보 보관기간 동안 CS응대 솔루션 제공</td>
          </tr>
          <tr>
            <td>Google LLC (수탁자), [googlekrsupport@google.com]</td>
            <td>미국</td>
            <td>문서 내 정보 기입 시 파일 저장</td>
            <td>문서에 포함된 정보</td>
            <td>문서 생성 및 협업 툴</td>
          </tr>
        </tbody>
      </table>
      <p className="first_level">
        7. 이용자 및 법정대리인의 권리와 그 행사방법
      </p>
      <p>
        - 이용자 본인 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를
        조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p>
        - 본 서비스는 만 14세 미만인 아동의 회원가입이 불가합니다. 이용자의
        개인정보 조회 및 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’
        등)을, 가입 해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인
        절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
        개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        - 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기
        전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지해 정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        - 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에
        따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </p>
      <p className="first_level">
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
      </p>
      회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
      운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에
      보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.
      회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
      <p className="second_level">가. 쿠키 등 사용 목적</p>
      <p>
        - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
      </p>
      <br />
      <p>
        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될
        때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
      </p>
      <p className="second_level">나. 쿠키 설정 거부 방법</p>
      <br />
      <p>
        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
      </p>
      <br />
      <p>설정방법 예(인터넷 익스플로어의 경우)</p>
      <br />
      <p>{`: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보`}</p>
      <br />
      <p>
        단 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수
        있습니다.
      </p>
      <p className="first_level">9. 개인정보의 기술적/관리적 보호 대책</p>
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
      변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
      대책을 강구하고 있습니다.
      <p className="second_level">가. 휴대전화번호 암호화</p>
      휴대전화번호는 암호화되어 저장 및 관리되고 있으며 개인정보의 확인 및
      변경도 계정 인증 후 본인에 의해서만 가능합니다.
      <p className="second_level">나. 해킹 등에 대비한 대책</p>
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나
      훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서
      자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 이용자들의
      개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며 암호화 통신
      등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단 시스템을 이용하여 외부로부터의 무단 접근을
      통제하고 있으며 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
      기술적 장치를 갖추려 노력하고 있습니다.
      <p className="second_level">다. 취급 직원의 최소화 및 교육</p>
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의
      비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을
      통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      <p className="first_level">10. 개인정보에 관한 민원서비스</p>
      <br />
      <p>
        회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
      </p>
      <br />
      <p>[개인정보관리책임자]</p>
      <p>- 성명 : 이건노</p>
      <p>- 소속/직위 : 제품개발그룹 / CTO</p>
      <p>- 전화번호 : 02-2070-2110</p>
      <p>- 이메일 : admin@opensurvey.co.kr</p>
      <br />
      <p>
        귀하께서는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련
        민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
        이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      </p>
      <br />
      <p>[개인정보보호 관련 담당부서]</p>
      <p>- 부서명: 오베이 고객센터</p>
      <p>- 전화번호: 02-2070-2110</p>
      <p>- 이메일: thanks@ovey.co.kr</p>
      <br />
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
      </p>
      <p>- 개인정보침해신고센터 (www.1336.or.kr/국번없이 118)</p>
      <p>- 대검찰청 인터넷범죄수사센터 (icic.sppo.go.kr/02-3480-3600) </p>
      <p>- 경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)</p>
      <p className="first_level">11. 정책변경에 따른 공지</p>
      <p className="second_level">
        가. 본 개인정보처리방침은 홈페이지 첫 화면에 공개함으로써 회원님께서
        언제나 용이하게 보실 수 있도록 조치하고 있습니다.
      </p>
      <p className="second_level">
        나. 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이
        있을 시에는 변경되는 개인정보처리방침을 시행하기 전에 홈페이지를 통해
        변경이유 및 내용 등을 공지하도록 하겠습니다.
      </p>
      <p className="second_level">
        다. 본 개인정보처리방침의 내용은 수시로 변경될 수 있으므로 사이트를
        방문하실 때마다, 이를 확인하시기 바랍니다.
      </p>
      <br />
      <br />
      <p>- 시행일자: 2022년 06월 29일</p>
    </div>
  ),
};
